import { useContext } from "react";
import LanguageContext from "../../context/language/LanguageContext";

function MenuListItem({ title, price, image, type }) {
  const { t } = useContext(LanguageContext);
  return (
    <div className="text-zinc-700 bg-white rounded-md px-3 py-5 flex flex-col items-center">
      <img
        className={
          type === "drinks" ? "h-24" : type === "bakery" ? "h-20" : "h-36"
        }
        src={image}
        alt={title}
      />
      <p className="mt-3 text-xl font-semibold text-center">{t(title)}</p>
      <div className="mt-4">
        {/* for hot menu price */}
        {price.hot && (
          <p className="font-semibold flex items-center text-gray-500">
            <span className="font-semibold text-2xl mr-1 text-lush">
              ฿{price.hot}
            </span>
            ({t("hot")})
          </p>
        )}
        {/* for cold menu price */}
        {price.cold && (
          <p className="font-semibold flex items-center text-gray-500">
            <span className="font-semibold text-2xl mr-1 text-lush">
              ฿{price.cold}
            </span>
            ({t("iced")})
          </p>
        )}
        {/* if menu item only has 1 price */}
        {typeof price !== "object" && (
          <p className="font-semibold flex items-center text-gray-500">
            <span className="font-semibold text-2xl mr-1 text-lush">
              ฿{price}
            </span>
          </p>
        )}
      </div>
    </div>
  );
}

export default MenuListItem;
