import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/slide.css";
import { Carousel } from "react-responsive-carousel";
import Navigation from "../components/layouts/shared/Navigation";
import images from "../components/assets/images/galleryImages";

function Gallery() {
  return (
    <>
      <div className="p-6 container mx-auto">
        <Navigation linkPath="/menu" />
      </div>

      <div className="container mx-auto">
        <Carousel emulateTouch={true} autoPlay={true} infiniteLoop={true}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={""} />
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default Gallery;
