import { createContext, useState } from 'react';
import data from '../../data/data.json';

const MenuContext = createContext();
export const MenuProvider = ({ children }) => {
  //Initialize data

  const [menus, setMenus] = useState(data);

  const categoriesList = [
    {
      text: 'all',
      isActive: true,
    },
    {
      text: 'food',
      isActive: false,
    },
    {
      text: 'bakery',
      isActive: false,
    },
    {
      text: 'drinks',
      isActive: false,
    },
    {
      text: 'coffee',
      isActive: false,
    },
    {
      text: 'tea',
      isActive: false,
    },
    {
      text: 'smoothie',
      isActive: false,
    },
    {
      text: 'nonCoffee',
      isActive: false,
    },
  ];

  const [categories, setCategories] = useState(categoriesList);

  // Change active category
  const updateCategories = (category) => {
    categories.forEach((item) => {
      item.isActive = false;
      if (item.text === category) {
        setCategories([...categories], (item.isActive = true));
      }
    });
  };

  // Update menu list through search and categories btns
  const findSearchResult = (text) => {
    const [{ drinks }, { food }, { bakery }] = data;
    const newDrinks = drinks.filter(
      (drink) =>
        drink.tags.includes(text.toLowerCase().replace(/\s+/g, '')) || // check for tags
        drink.title.toLowerCase().includes(text.toLowerCase()) || // check in names
        drink.titleTH.includes(text) // check in Thai names
    );
    const newBakery = bakery.filter(
      (bakery) =>
        bakery.tags.includes(text.toLowerCase().replace(/\s+/g, '')) ||
        bakery.title.toLowerCase().includes(text.toLowerCase()) ||
        bakery.titleTH.includes(text)
    );
    const newFood = food.filter(
      (food) =>
        food.tags.includes(text.toLowerCase().replace(/\s+/g, '')) ||
        food.title.toLowerCase().includes(text.toLowerCase()) ||
        food.titleTH.includes(text)
    );
    setMenus([
      { drinks: [...newDrinks] },
      { food: [...newFood] },
      { bakery: [...newBakery] },
    ]);
  };

  return (
    <MenuContext.Provider
      value={{
        categories,
        menus,
        updateCategories,
        findSearchResult,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
