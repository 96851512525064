import { BrowserRouter, Routes, Route } from "react-router-dom";
import { MenuProvider } from "./context/menu/MenuContext";
import { LanguageProvider } from "./context/language/LanguageContext";
import HomePage from "./pages/HomePage";
import Menu from "./pages/Menu";
import NotFound from "./pages/NotFound";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";

function App() {
  return (
    <LanguageProvider>
      <MenuProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </MenuProvider>
    </LanguageProvider>
  );
}

export default App;
