import { useState } from "react";
import { FaChevronUp } from "react-icons/fa";
import "../../../css/animation.css";

function ScrollToTop() {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 600) {
      setVisible(true);
    } else if (scrolled <= 600) {
      setVisible(false);
    }
  };
  const scrollToTop = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      <button
        onClick={scrollToTop}
        className={visible ? "btn--scroll active" : "btn--scroll"}
      >
        <FaChevronUp className="text-white relative  mx-auto" />
      </button>
    </>
  );
}

export default ScrollToTop;
