import { createContext } from "react";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";

const LanguageContext = createContext();
export const LanguageProvider = ({ children }) => {
  const { t } = useTranslation();

  return (
    <LanguageContext.Provider value={{ t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
