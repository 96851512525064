import { useContext } from "react";
import MenuContext from "../../context/menu/MenuContext";
import MenuListItem from "./MenuListItem";

function MenuList() {
  const { menus } = useContext(MenuContext);
  const [{ drinks }, { food }, { bakery }] = menus;
  return (
    // <div className='mt-7 grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 gap-4'>
    //   {menus.map((menu, index) => (
    //     <MenuListItem
    //       key={index}
    //       title={menu.title}
    //       price={menu.price}
    //       image={menu.image}
    //     />
    //   ))}
    // </div>
    <>
      <div className="mt-7 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4">
        {drinks.map((drink, index) => (
          <MenuListItem
            key={index}
            title={drink.title}
            price={drink.price}
            image={drink.image}
            type={"drinks"}
          />
        ))}
      </div>
      <div className="mt-7 grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4">
        {bakery.map((item, index) => (
          <MenuListItem
            key={index}
            title={item.title}
            price={item.price}
            image={item.image}
            type={"bakery"}
          />
        ))}
      </div>
      <div className="mt-7 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-4">
        {food.map((item, index) => (
          <MenuListItem
            key={index}
            title={item.title}
            price={item.price}
            image={item.image}
            type={"food"}
          />
        ))}
      </div>
    </>
  );
}

export default MenuList;
