import { FaSearch, FaCoffee } from "react-icons/fa";
import { useState, useContext } from "react";
import MenuContext from "../../context/menu/MenuContext";
import LanguageContext from "../../context/language/LanguageContext";

function Search() {
  const { t } = useContext(LanguageContext);

  const [text, setText] = useState("");
  const { findSearchResult } = useContext(MenuContext);
  const handleChange = (e) => {
    setText(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    findSearchResult(text);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="relative mt-7 flex">
        <div className="flex-1">
          <FaSearch className="absolute left-0 top-1/2 -translate-y-1/2 text-md text-gray-500 ml-4" />
          <input
            onChange={handleChange}
            type="text"
            placeholder={t("placeholder")}
            className="bg-gray-200 h-12 pl-12 text-lg rounded-md w-full"
          />
        </div>
        <div className="ml-3">
          <button className="bg-lush h-12 w-12 rounded-md">
            <FaCoffee className="text-2xl text-white mx-auto " />
          </button>
        </div>
      </div>
    </form>
  );
}

export default Search;
