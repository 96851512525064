import "../../../css/animation.css";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {motion} from 'framer-motion'
import LanguageContext from "../../../context/language/LanguageContext";

function Hamburger() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else if (!isOpen) {
      setIsOpen(true);
    }
  };

  const { t } = useContext(LanguageContext);

  const links = [
    {
      title: t("contact"),
      path: "contact",
    },
    {
      title: t("gallery"),
      path: "gallery",
    },
  ];

  const variants ={
    hidden: {
      width: "0%",
      height: "0%",
      borderRadius: "100%",
      transition: {
        duration: 0.3
      }
    },
    visible: {
      width: "150%",
      height: "150%",
      borderRadius: "25%",
      transition: {
        duration: 0.3
      }
    }
  }

  return (
    <div className="relative">
      <div
        onClick={handleClick}
        className="hamburger absolute right-0 top-0 cursor-pointer z-50"
      >
        <div className={isOpen ? "active" : ""}></div>
        <div className={isOpen ? "active" : ""}></div>
        <div className={isOpen ? "active" : ""}></div>
      </div>

      {/* Larger screen menu items */}
      <ul className="flex gap-10">
        {links.map((link, index) => (
          <Link key={index} to={`/${link.path}`}>
            <li className="text-lg font-semibold menu--item relative">
              {link.title}
            </li>
          </Link>
        ))}
      </ul>

      <div className="hamburger-container z-10">
        <motion.div variants={variants} initial={false} animate={isOpen ? 'visible' : 'hidden'} style={{x: '50%', y: '-50%'}} className={`${isOpen ? '' : 'hidden'} hamburger-menu fixed w-full h-full bg-lush top-1/2 right-1/2`}>
          <ul className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            {links.map((link, index) => (
              <Link key={index} to={`/${link.path}`} onClick={handleClick}>
                <li className="hamburger-menu--item relative text-center text-white font-semibold text-2xl mb-10">
                  {link.title}
                </li>
              </Link>
            ))}
          </ul>
        </motion.div>
      </div>
    </div>
  );
}

export default Hamburger;
