import { useContext } from "react";
import MenuContext from "../../context/menu/MenuContext";
import LanguageContext from "../../context/language/LanguageContext";

function SliderButton({ text, isActive, value }) {
  const { findSearchResult, updateCategories } = useContext(MenuContext);
  const { t } = useContext(LanguageContext);
  const handleClick = (e) => {
    findSearchResult(e.target.value);
    updateCategories(e.target.value);
  };

  const active =
    "border-2 rounded-md border-lush text-white px-5 py-2 mr-3 bg-lush whitespace-nowrap";
  const notActive =
    "border-2 rounded-md border-lush text-lush px-5 py-2 mr-3 whitespace-nowrap";

  return (
    <button
      value={text}
      onClick={handleClick}
      className={isActive ? active : notActive}
    >
      {t(text)}
    </button>
  );
}

export default SliderButton;
