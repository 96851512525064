import SliderButton from "./SliderButton";
import { useContext } from "react";
import { BsArrowLeft } from "react-icons/bs";
import MenuContext from "../../context/menu/MenuContext";
import LanguageContext from "../../context/language/LanguageContext";

function Slider() {
  const { categories } = useContext(MenuContext);
  const { t } = useContext(LanguageContext);

  // const y = [
  //   {
  //     text: t("categories.0"),
  //     isActive: true,
  //   },
  //   {
  //     text: t("categories.1"),
  //     isActive: false,
  //   },
  // ];

  return (
    <>
      <div className="overflow-x-auto flex mt-7">
        {categories.map(({ text, isActive, value }, index) => (
          <SliderButton
            key={index}
            text={text}
            isActive={isActive}
            value={value}
          />
        ))}
      </div>
      <div className="my-3 flex items-center justify-end text-neutral-400 font-lush font-bold text-sm">
        <BsArrowLeft />
        <p className="ml-2">{t("swipe")}</p>
      </div>
    </>
  );
}

export default Slider;
