import markerImage from "../components/assets/images/marker.png";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef, useState, useContext } from "react";
import LanguageContext from "../context/language/LanguageContext";
import Navigation from "../components/layouts/shared/Navigation";
import { FaInstagram, FaFacebook } from "react-icons/fa";

const MapComponent = () => {
  const ref = useRef(null);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({
    lat: 18.80546527358206,
    lng: 98.96384563320774,
  });

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current));
    }
  }, [ref, map]);

  const createMarker = () => {
    return new window.google.maps.Marker({
      position: center,
      map,
      icon: markerImage,
      // draggable: true,
      optimized: true,
    });
  };

  const createInfoWindow = () => {
    const text = `<div class="p-4 text-center">
        <h3 class="font-bold">
          Lush Cafe & Garden
        </h3>
        <p class="mt-2 text-lush"><a href="https://goo.gl/maps/QVPfseMQ62idJxZG7" target="_blank">Click here for directions</a></p>
      </div>`;
    return new window.google.maps.InfoWindow({
      content: text,
    });
  };

  useEffect(() => {
    if (map) {
      map.setOptions({ center, zoom: 16 });
      const marker = createMarker();
      marker.addListener("click", (e) => {
        const popup = createInfoWindow();
        popup.open(map, marker);
      });
    }
  }, [center, map]);

  return <div ref={ref} style={{ width: "100%", height: "100%" }}></div>;
};

const Contact = () => {
  // Translation
  const { t } = useContext(LanguageContext);
  const render = (status) => {
    if (status === Status.LOADING)
      return <div className="text-center">Loading...</div>;
    if (status === Status.FAILURE) return <div>Failed to load.</div>;
    return <div>{status}</div>;
  };

  return (
    <>
      {/* Navigation */}
      <div className="p-6 container mx-auto">
        <Navigation linkPath="/menu" />
      </div>

      <div className="flex flex-col h-screen md:grid md:grid-cols-2 h-[calc(100vh-77px)]">
        {/* Map */}
        <div style={{ height: "100%", width: "100%" }}>
          <Wrapper
            apiKey="AIzaSyDUXwerhnIBaMdENImdHajJBTeZ3rc0CVw"
            render={render}
            language="en"
          >
            <MapComponent />
          </Wrapper>
        </div>

        {/* Contact Info */}
        <div className="bg-lush flex-1">
          <div className="px-6 py-10 md:p-12 lg:p-20 xl:p-40 container h-full mx-auto md:flex md:flex-col md:justify-center">
            <div className="text-white text-lg">
              <h4 className="mb-4 uppercase font-bold">{t("location")}</h4>
              <p className="mt-2 ml-6">
                {t("address1")} <br />
                {t("address2")} <br />
                {t("address3")} <br />
              </p>
              <p className="mt-2 ml-6">Tel: 053 211 333</p>
            </div>
            <div className="my-12">
              <a
                className="rounded-full border-2 text-white px-8 py-2 hover:bg-white hover:text-lush transition-colors ease-in-out duration-300"
                href="tel:0828915558"
              >
                {t("call")}
              </a>
              <a
                className="ml-3 rounded-full border-2 text-white px-8 py-2 hover:bg-white hover:text-lush transition-colors ease-in-out duration-300"
                href="https://lin.ee/mz5It3o"
              >
                {t("line")}
              </a>
            </div>

            <div className="flex items-center text-white gap-4">
              <a href="https://www.instagram.com/lushcafe.cnx/">
                <FaInstagram className="text-2xl" />
              </a>
              <a href="https://www.instagram.com/lushcafe.cnx/">
                <FaFacebook className="text-2xl" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
