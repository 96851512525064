export const TRANSLATIONS_TH = {
  //Menu page
  header: "ค้นหาเมนูโปรดของคุณ",
  back: "กลับ",
  gallery: "รุปภาพ",
  contact: "ติดต่อเรา",
  placeholder: "กระเพราหมู",
  iced: "เย็น",
  hot: "ร้อน",
  swipe: "เลื่อนดู",

  // Categories
  all: "ทั้งหมด",
  food: "อาหาร",
  bakery: "เบเกอร์รี่",
  drinks: "เครื่องดื่ม",
  coffee: "กาแฟ",
  tea: "ชา",
  smoothie: "สมูตตี้",
  nonCoffee: "เครื่องดื่มไม่มีกาแฟ",

  //Menu Items
  americano: "อาเมริกาโน่",
  latte: "ลาเต้",
  cappucino: "คาปูชิโน่",
  mocha: "ม๊อคค่า",
  caramelLatte: "คาราเมล ลาเต้",
  vanillaLatte: "วานิลา ลาเต้",
  esYen: "เอสเย็น",
  americanoOrange: "อาเมริกาโน่ส้ม",
  americanoHoneyLemon: "อาเมริกาโน่น้ำผึ้งมะนาว",
  thaiMilkTea: "ชาไทยเย็น",
  greenMilkTea: "ชาเขียวเย็น",
  lemonIcedTea: "ชามานาว",
  peachTea: "ชาพีช",
  matchaLatte: "มัชฉะลาเต้",
  earlGreyHoney: "ชาเอิร์ลเกรย์น้ำผึ้ง",
  earlGreyLemon: "ชาเอิร์ลเกรย์เลม่อน",
  earlGreyLitchee: "ชาเอิร์ลเกรย์ลิ้นจี่",
  lemonSoda: "เลม่อนโซดา",
  litcheeSoda: "ลิ้นจี่โซดา",
  passionfruitSoda: "เสาวรสโซดา",
  bananaPeanutbutterSmoothie: "กล้วยเนยถั่วสมูทตี้",
  strawberrySmoothie: "สตรอว์เบอร์รี่สมูทตี้",
  mixedBerrySmoothie: "มิกซ์เบอร์รี่สมูทตี้",
  mangoSmoothie: "สมูทตี้มะม่วง",
  watermelonBlend: "แตงโมปั่น",
  passionfruitBlend: "เสาวรสปั่น",

  // => Bakery
  croissant: "ครัวซอง",
  hamCheeseCroissant: "แฮมชีสครัวซอง",

  // => Food
  frenchFries: "เฟรนช์ฟรายส์",
  cheeseBall: "ชีสบอล",
  panFriedEgg: "ไข่กระทะ",
  eggsAndBacon: "ไข่ดาว / ไข่คน กับเบคอน",
  clubSandwich: "คลับแซนวิช",
  shrimpPorridge: "ข้าวต้มกุ้ง",
  stirFriedHolyBasilAndPorkWithFriedEgg: "กระเพราหมูสับไข่ดาว",
  thaiSpicyShrimpPasteFriedRice: "ข้าวผัดน้ำพริกลงเรือ",
  northernStyleGreenChilliFriedRice: "ข้าวผัดน้ำพริกหนุ่ม",
  northernStyleLarbFriedRice: "ข้าวผัดคลุกลาบไข่ต้ม",
  northernStyleChilliDipFriedRice: "ข้าวผัดน้ำพริกอ่องไส้อั่ว",
  stirFriedHolyBasilAndPorkWithEgg: "ข้าวไข่ข้นกระเพราหมูสับ",
  grilledChickenTeriyakiSauceWithEgg: "ข้าวไข่ข้นไก่ย่างซอสเทอริยากิ",
  shrimpTomYumWithEgg: "ข้าวไข่ข้นต้มยำกุ้งผัดแห้ง",
  stirFriedSaltAndChilliCrispyChickenWithEgg: "ข้าวไข่ข้นไก่ทอดผัดพริกเกลือ",
  stirFriedSaltAndChilliCrispyFishWithEgg: "ข้าวไข่ข้นปลาทอดผัดพริกเกลือ",
  curedSalmonFriedRice: "ข้าวผัดปลาแซลมอนเค็ม",
  pineappleFriedRice: "ข้าวอบสับปะรด",
  spaghettiAglioEOlioWithBaconAndSausage: "สปาเก็ตตี้ผัดพริกแห้งเบคอนไส้กรอก",
  spaghettiNorthernThaiSausage: "สปาเก็ตตี้ไส้อั่ว",
  spaghettiSeafoodWithThaiBasil: "สปาเก็ตตี้ขี้เมาทะเล",
  spaghettiHamAndMushroomCreamSauce: "สปาเก็ตตี้ซอสครีมแฮมเห็ด",
  stirFriedRiceNoodlesWithChicken: "ก๋วยเตี๋ยวคั่วไก่",
  riceNoodlesWithSeafoodInGravy: "ก๋วยเตี๋ยวราดหน้าทะเล",
  shrimpPadThai: "ผัดไทยกุ้ง",

  //Contact page
  location: "ที่อยู่",
  address1: "16/16 โรงแรมฮอลิเดย์การ์เดน",
  address2: "ถนน ห้วยแก้ว, อำเภอ เมือง",
  address3: "จังหวัดเชียงใหม่, 50300",
  address4: "เบอร์โทร: 053 211 333",
  call: "โทรติดต่อ",
  line: "แอดไลน์",
};
