import img1 from './gallery/g-01.jpg';
import img2 from './gallery/g-02.jpg';
import img3 from './gallery/g-03.jpg';
import img4 from './gallery/g-04.jpg';
import img5 from './gallery/g-05.jpg';
import img6 from './gallery/g-06.jpg';
import img7 from './gallery/g-07.jpg';
import img8 from './gallery/g-08.jpg';
import img9 from './gallery/g-09.jpg';
import img10 from './gallery/g-10.jpg';
import img11 from './gallery/g-11.jpg';
import img12 from './gallery/g-12.jpg';
import img13 from './gallery/g-13.jpg';
import img14 from './gallery/g-14.jpg';
import img15 from './gallery/g-15.jpg';
import img16 from './gallery/g-16.jpg';

const images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
];

export default images;
