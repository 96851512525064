import logo from "../components/assets/images/logo-white.png";
import { Link } from "react-router-dom";
import { changeLanguage } from "i18next";
import "../translations/i18n";

function HomePage() {
  const changeLang = (e) => {
    changeLanguage(e.target.value);
  };

  return (
    <div className="bg-homepage bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col h-screen justify-between items-center py-20">
        <div className="w-24">
          <img src={logo} alt="Logo" />
        </div>

        <div className="flex flex-col items-center px-8">
          <h1 className="text-white font-semibold text-3xl">
            We hope you enjoy exploring our menu
          </h1>
          <div className="mt-14 mb-6 flex w-full justify-between">
            <Link to="/menu">
              <button
                value="en"
                // onClick={pickLang}
                onClick={changeLang}
                className="bg-lush w-36 xs:w-36 md:w-48 py-3 rounded-md text-white text-lg uppercase font-semibold"
              >
                English
              </button>
            </Link>
            <Link to="/menu">
              <button
                value="th"
                // onClick={pickLang}
                onClick={changeLang}
                className="bg-lush w-36 xs:w-36 md:w-48 py-3 rounded-md text-white text-lg font-semibold"
              >
                ภาษาไทย
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
