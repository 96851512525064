import { useContext, useEffect } from "react";
import MenuContext from "../context/menu/MenuContext";
import Search from "../components/layouts/Search";
import Slider from "../components/layouts/Slider";
import MenuList from "../components/layouts/MenuList";
import Navigation from "../components/layouts/shared/Navigation";
import ScrollToTop from "../components/layouts/shared/ScrollToTop";
import LanguageContext from "../context/language/LanguageContext";

function Menu() {
  const { findSearchResult, updateCategories } = useContext(MenuContext);
  const { t } = useContext(LanguageContext);

  useEffect(() => {
    findSearchResult("All");
    updateCategories("all");
  }, []);

  return (
    <div className="bg-mainBg min-h-screen">
      <div className="p-6 container mx-auto">
        <Navigation linkPath="/" />
        <h2 className="text-2xl mt-14">{t("header")}</h2>
        <Search />
        <Slider />
        <MenuList />
      </div>
      <ScrollToTop />
    </div>
  );
}

export default Menu;
