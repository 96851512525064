import { useContext } from "react";
import { Link } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import Hamburger from "./Hamburger";
import LanguageContext from "../../../context/language/LanguageContext";

const Navigation = ({ linkPath }) => {
  const { t } = useContext(LanguageContext);

  return (
    <div className="flex justify-between">
      <Link to={linkPath}>
        <p className="flex items-center text-lg font-semibold">
          <FaChevronLeft className="inline mr-2" />
          {t("back")}
        </p>
      </Link>
      <Hamburger />
    </div>
  );
};

export default Navigation;
