export const TRANSLATIONS_EN = {
  // Menu page
  header: "Find your favorite dish",
  back: "back",
  gallery: "Gallery",
  contact: "Contact",
  placeholder: "krapaomoo",
  iced: "iced",
  hot: "hot",
  swipe: "swipe",

  // Categories
  all: "All",
  food: "Food",
  bakery: "Bakery",
  drinks: "Drinks",
  coffee: "Coffee",
  tea: "Tea",
  smoothie: "Smoothie",
  nonCoffee: "Non-coffee",

  //Menu Items
  // => Drinks
  americano: "Americano",
  latte: "Latte",
  cappucino: "Cappucino",
  mocha: "Mocha",
  caramelLatte: "Caramel Latte",
  vanillaLatte: "Vanilla Latte",
  esYen: "Es-Yen",
  americanoOrange: "Americano Orange",
  americanoHoneyLemon: "Americano Honey Lemon",
  thaiMilkTea: "Thai Milk Tea",
  greenMilkTea: "Thai Milk Tea",
  lemonIcedTea: "Lemon Iced Tea",
  peachTea: "Peach Tea",
  matchaLatte: "Matcha Latte",
  earlGreyHoney: "Earl Grey Honey",
  earlGreyLemon: "Earl Grey Lemon",
  earlGreyLitchee: "Earl Grey Litchee",
  lemonSoda: "Lemon Soda",
  litcheeSoda: "Litchee Soda",
  passionfruitSoda: "Passionfruit Soda",
  bananaPeanutbutterSmoothie: "Banana Peanut Butter Smoothie",
  strawberrySmoothie: "Strawberry Smoothie",
  mixedBerrySmoothie: "Mixed Berry Smoothie",
  mangoSmoothie: "Mango Smoothie",
  watermelonBlend: "Watermelon Blend",
  passionfruitBlend: "Passionfruit Blend",

  // => Bakery
  croissant: "Croissant",
  hamCheeseCroissant: "Ham Cheese Croissant",

  // => Food
  frenchFries: "French Fries",
  cheeseBall: "Cheese Ball",
  panFriedEgg: "Pan Fried Egg",
  eggsAndBacon: "Eggs & Bacon",
  clubSandwich: "Club Sandwich",
  shrimpPorridge: "Shrimp Porridge",
  stirFriedHolyBasilAndPorkWithFriedEgg:
    "Stir-fried Holy Basil And Pork With Fried Egg",
  thaiSpicyShrimpPasteFriedRice: "Thai Spicy Shrimp Paste Fried Rice",
  northernStyleGreenChilliFriedRice: "Nothern Style Green Chilli Fried Rice",
  northernStyleLarbFriedRice:
    'Northern Style "Larb" Fried Rice with Boiled Egg',
  northernStyleChilliDipFriedRice:
    'Northern Style Chilli Dip Fried Rice with "Sai Ua"',
  stirFriedHolyBasilAndPorkWithEgg: "Stir-fried Holy Basil And Pork With Egg",
  grilledChickenTeriyakiSauceWithEgg: "Grilled Chicken Teriyaki Sauce with Egg",
  shrimpTomYumWithEgg: "Shrimp Tom Yum with Egg",
  stirFriedSaltAndChilliCrispyChickenWithEgg:
    "Stir-fried Salt & Chilli Crispy Chicken with Egg",
  stirFriedSaltAndChilliCrispyFishWithEgg:
    "Stir-fried Salt & Chilli Crispy Fish with Egg",
  curedSalmonFriedRice: "Cured Salmon Fried Rice",
  pineappleFriedRice: "Pineapple Fried Rice",
  spaghettiAglioEOlioWithBaconAndSausage:
    "Spaghetti Aglio e Olio with Bacon & Sausage",
  spaghettiNorthernThaiSausage: "Spaghetti Northern Thai Sausage",
  spaghettiSeafoodWithThaiBasil: "Spaghetti Seafood with Thai Basil",
  spaghettiHamAndMushroomCreamSauce: "Spaghetti Ham & Mushroom Cream Sauce",
  stirFriedRiceNoodlesWithChicken: "Stir Fried Rice Noodles with Chicken",
  riceNoodlesWithSeafoodInGravy: "Rice Noodles with Seafood in Gravy",
  shrimpPadThai: "Shrimp Pad-Thai",

  //Contact page
  location: "Location",
  address1: "16/16 Holiday Garden Hotel",
  address2: "Huay Kaew Road, Muang District",
  address3: "Chiang Mai, Thailand 50300",
  address4: "Tel: 053 211 333",
  call: "Call us",
  line: "Add Line",
};
